/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { useDispatch, useSelector } from 'react-redux';
import { userDetailsSelector, userDetailsActions } from './UserDetailsStore';
import { IOrder, ISortBy } from './_types';
import { MAX_AGE_USER_DETAILS_MS } from '../UserDetailConstants';
import { getAuthorization } from '../../utils/roleAssignment/roleAssignment';
import { IAccessRole } from '../../utils/roleAssignment/typesAndConstants';
import { useMemo } from 'react';
import { RoleAssignmentWithOptionalId, convertToRoleAssignment } from '../../api/dto';
import { NiagaraUserDetail } from '../../api/management';
import {
  removeCustomerRoleAssignmentAsync,
  removeOrganizationRoleAssignmentAsync,
  updateCustomerRoleAssignmentsAsync,
  updateOrganizationRoleAssignmentsAsync,
  updateRoleAssignmentsAsync,
} from './useUserStorePromises';
import { useAuthenticatedUser } from '../../utils/Authentication';

export function useUserDetailsStore(userId?: number) {
  const { currentUser } = useAuthenticatedUser();
  const dispatch = useDispatch();
  const state = useSelector(userDetailsSelector);
  const user = state.users.find(user => user.id === userId);

  const overallUserAccess = useMemo(() => {
    const roleAssignments = user?.roleAssignments.map(convertToRoleAssignment) ?? [];
    const authorizationPaths = user?.organizationId !== undefined ? [`/organizations/${user.organizationId}`] : [];
    return getAuthorization(roleAssignments, authorizationPaths).overall;
  }, [user?.roleAssignments, user?.organizationId]);

  return {
    ...state,
    overallUserAccess,
    resetPage() {
      dispatch(userDetailsActions.resetPage());
    },
    async setOverallAccess(accessRole: IAccessRole) {
      if (user) {
        const roleAssignments = await updateOrganizationRoleAssignmentsAsync(user, accessRole);
        dispatch(userDetailsActions.updateUserRoleAssignments({ userId: user.id, roleAssignments }));
      }
    },
    async removeOverallAccess(accessRole: IAccessRole) {
      if (user) {
        const roleAssignments = await removeOrganizationRoleAssignmentAsync(user, accessRole);
        dispatch(userDetailsActions.updateUserRoleAssignments({ userId: user.id, roleAssignments }));
      }
    },
    async setCustomerAccess(accessRole: IAccessRole, customerId: number | undefined) {
      if (user && customerId) {
        const roleAssignments = await updateCustomerRoleAssignmentsAsync(user, accessRole, customerId);
        dispatch(userDetailsActions.updateUserRoleAssignments({ userId: user.id, roleAssignments }));
      }
    },
    async removeCustomerAccess(accessRole: IAccessRole, customerId: number | undefined) {
      if (user && customerId) {
        const roleAssignments = await removeCustomerRoleAssignmentAsync(user, accessRole, customerId);
        dispatch(userDetailsActions.updateUserRoleAssignments({ userId: user.id, roleAssignments }));
      }
    },
    async setAccess(localRoleAssignments: RoleAssignmentWithOptionalId[]) {
      if (user) {
        const roleAssignments = await updateRoleAssignmentsAsync(user, localRoleAssignments);
        dispatch(userDetailsActions.updateUserRoleAssignments({ userId: user.id, roleAssignments }));
      }
    },
    getPendingInvitation(pagePendingInvitation: number, size: number, sort?: ISortBy, order?: IOrder, keyword?: string, force?: boolean) {
      if (
        force ||
        state.usersError !== undefined ||
        Date.now() - state.loadedTime > MAX_AGE_USER_DETAILS_MS ||
        state.pagePendingInvitation?.number !== pagePendingInvitation ||
        state.pagePendingInvitation?.size !== size ||
        state.lastCall?.sort !== sort ||
        state.lastCall?.order !== order ||
        state.lastCall?.keyword !== keyword
      ) {
        dispatch(userDetailsActions.getPendingInvitationAsync({ currentUser, pagePendingInvitation, size, sort, order, keyword }));
      }
    },
    getUsers(page: number, size: number, sort?: ISortBy, order?: IOrder, keyword?: string, force?: boolean) {
      if (
        force ||
        state.usersError !== undefined ||
        Date.now() - state.loadedTime > MAX_AGE_USER_DETAILS_MS ||
        state.page?.number !== page ||
        state.page?.size !== size ||
        state.lastCall?.sort !== sort ||
        state.lastCall?.order !== order ||
        state.lastCall?.keyword !== keyword
      ) {
        dispatch(userDetailsActions.getUsersAsync({ currentUser, page, size, sort, order, keyword }));
      }
    },
    loadUser(userId: number) {
      dispatch(userDetailsActions.loadUserAsync({ userId, currentUser }));
    },
    getUserCustomers(user: NiagaraUserDetail) {
      dispatch(userDetailsActions.getUserCustomersAsync(user));
    },
  };
}
