/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { DeviceWithProjectId } from '../../projectdevice/DeviceWithProjectId';
import { PAGE_SIZE_OF_POINTS } from '../../utils/Constants/chartConstants';
import { niagaraHttp } from '../../utils/niagaraHttp';
import { readAsync } from '../live';
import { Page } from '../Page';
import { Paged } from '../Paged';

export interface Tag {
  tagId: string;
  tagValue: string;
}
export interface Facet {
  key: string;
  type: string;
  value: string | number | boolean | Object;
}
export interface IPointModel {
  systemGuid: string;
  cloudId: string;
  displayName: string;
  tags: Tag[];
  outValue: string | undefined;
  facets: Facet[];
  controlPoint?:boolean,
  historyEnabled?:boolean,
  links: {
    sourceSlot: string;
    sourceId: string;
    targetId: string;
    targetSlot: string;
  }[];
}

type SearchBy = 'tagValues' | 'tagNames' | 'pointNames';
type PointSearchPayload = {
  systemGuid: string;
  comparisonType?: string;
  searchItems: string[];
  searchType: 'tagName' | 'tagValue' | 'pointName';
};

export async function getPointsAnyPointNameAsync(
  customerId: number,
  systemGuid: string,
  searchItems: string[],
  page: Pick<Page, 'number' | 'size'> | undefined = undefined,
  sortBy: string = 'id',
  order: string = 'asc',
  enableLiveReadWritePage?: boolean,
) {
  return getAllPointsAsync(
    customerId,
    'pointNames',
    {
      systemGuid,
      comparisonType: searchItems.length ? 'contains' : 'any',
      searchItems,
      searchType: 'pointName',
    },
    page,
    sortBy,
    order,
    enableLiveReadWritePage,
  );
}
export async function getPointsContainsTagValueAsync(
  customerId: number,
  systemGuid: string,
  searchItems: string[],
  page: Pick<Page, 'number' | 'size'> | undefined = undefined,
  sortBy: string = 'id',
  order: string = 'asc',
  enableLiveReadWritePage?: boolean,
) {
  return await getAllPointsAsync(
    customerId,
    'tagValues',
    {
      systemGuid,
      searchItems,
      searchType: 'tagValue',
      comparisonType: searchItems.length ? (searchItems.length > 1 ? 'exact' : 'contains') : 'any',
    },
    page,
    sortBy,
    order,
    enableLiveReadWritePage,
  );
}
export async function getPointsContainsTagNameAsync(
  customerId: number,
  systemGuid: string,
  searchItems: string[],
  page: Pick<Page, 'number' | 'size'> | undefined = undefined,
  sortBy: string = 'id',
  order: string = 'asc',
  enableLiveReadWritePage?: boolean,
) {
  return await getAllPointsAsync(
    customerId,
    'tagNames',
    {
      systemGuid,
      searchItems,
      searchType: 'tagName',
      comparisonType: 'contains',
    },
    page,
    sortBy,
    order,
    enableLiveReadWritePage,
  );
}

export async function getAllPointsAsync(
  customerId: number,
  searchBy: SearchBy,
  payload: PointSearchPayload,
  page: Pick<Page, 'number' | 'size'> | undefined,
  sortBy?: string,
  order?: string,
  enableLiveReadWritePage?: boolean,
): Promise<IPointModel[] | { pointModels?: IPointModel[] | undefined; page: Page }> {
  const p: IPointModel[] = [];
  if (page) {
    const pointsResult = await getPointsAsync(
      customerId,
      searchBy,
      page?.number,
      sortBy,
      order,
      payload,
      page?.size
    );
    const { page: resultPage, _embedded } = pointsResult;

    if (enableLiveReadWritePage) {
      if (_embedded?.pointModels && _embedded.pointModels.length) {
        const systemGuid = _embedded.pointModels[0].systemGuid;
        const cloudIds = _embedded.pointModels.map((p) => p.cloudId);

        try {
          const readResult = await readAsync(systemGuid, cloudIds);

          const { pointReadDetails } = readResult;

          _embedded.pointModels = _embedded.pointModels.map((p) => {
            const pointDetail = pointReadDetails.find(
              (detail: any) => detail.cloudId === p.cloudId
            );
            if (pointDetail) {
              p.outValue = pointDetail.value + pointDetail.status;
            }
            return p;
          });
        } catch (err) {
          _embedded.pointModels = _embedded.pointModels.map((p) => {
            p.outValue = undefined;
            return p;
          });
        }
      }
    }

    return { page: resultPage, ..._embedded };
  } else {
    for (let page = 0, totalPages = 1; page < totalPages; page++) {
      await getPointsAsync(
        customerId,
        searchBy,
        0,
        sortBy,
        order,
        payload
      ).then(({ page, _embedded }) => {
        totalPages = page.totalPages;
        if (_embedded) p.push(..._embedded.pointModels);
      });
    }
    return p;
  }
}

export async function getPointsAsync(
  customerId: number,
  searchBy: SearchBy,
  page: number,
  sortBy: string,
  order: string,
  payload: PointSearchPayload,
  size: number = PAGE_SIZE_OF_POINTS
) {
  return await niagaraHttp.post<
    Paged<undefined | { pointModels: IPointModel[] }>
  >(
    `${window.ENV.API_BASE_URL}/api/v1/entitymodel/customers/${customerId}/${searchBy}?page=${page}&size=${size}&count=true&sortBy=${sortBy}&sortDir=${order}`,

    payload
  );
}

export async function getPointsCountAsync(deviceDetails: DeviceWithProjectId) {
  const result = await getPointsAsync(
    deviceDetails.customerId,
    'tagValues',
    0,
    'id',
    'asc',
    {
      systemGuid: deviceDetails.systemGuid as string,
      searchItems: [
       'n:point'
      ],
      searchType: 'tagName',
      comparisonType: 'exact',
    },
    1
  );
  return result.page.totalElements;
}
