/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import { niagaraHttp } from '../../utils/niagaraHttp';

export type IRoleAssignment = {
  role: string;
  scope: string;
};
export type IUserInvitation = {
  customer: string;
  partner: string;
  email: string;
  roleAssignments: IRoleAssignment[];
};
export async function sendUserInvitationAsync(body: IUserInvitation) {
  return niagaraHttp.post<boolean>(
    `${window.ENV.API_BASE_URL}/api/v2/management/userInvitations`,
    body
  );
}
